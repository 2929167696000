@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

html {
    font-family: var(--primary-font);
    color: var(--dark-color);
    scroll-behavior: smooth;
    overflow-x: hidden;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: var(--primary-color)
}

body {
    overflow-x: hidden;
    background-color: var(--primary-color);
}

:root {
    --primary-font: 'Plus Jakarta Sans', sans-serif;
    --secondary-font: 'Open Sans', sans-serif;
    --dark-color: #000000;
    --primary-color: #791DB7;
    --info-color: #23907d;
    --success-color: #189d0e;
    --warning-color: #123123;
    --danger-color: #e20000;
    --secondary-color: #707070;
    --light-color: #bbb;
    --white-color: #fff;
    --general-transition: all .4s ease;
}

.app-container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    color: #ffffff;
}

.app-header{
    position: relative;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
}

.app-header-logo{
    display: block;
    position: relative;
}

.app-header-logo img{
    height: 120px;
    width: 300px;
}

.app-header-contact{
    display: block;
    align-items: center;
}

.app-header-contact-button{
    background-color: purple;
    padding: 15px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
}

.app-content{
    justify-content:space-between;
    align-items: center;
    display: flex;
}

.content-mobile-app-text-1{
    font-size:50px;
    font-weight: bold;
}

.content-mobile-app-text-2{
    font-size:54px;
}

.content-mobile-app-text-3{
    font-size: 30px;
    font-weight: bold;
}

.content-mobile-app-text-4{
    font-size: 30px;
    font-weight: 500;
}

.mobile-app-store-content{
    display:flex;
    gap:10px;
    margin-top:75px
}

.mobile-app-store-content-detail{
    display:flex;
    align-items:center;
    text-align: center;
    gap:10px;
}

.mobile-app-store-content-detail-item{
    display:flex;
    padding:20px;
    justify-content: center;
    width: 200px;
    align-items: center;
    gap:5px;
    border-radius: 10px;
    cursor: pointer;
}

.content-mobile-app{
    vertical-align: center;
    justify-content: center;
    padding:15px;
}

.app-header-contact{
    display: block;
    align-items: center;
}

.app-footer{
    display: block;
    position: relative;
    padding:10px
}

.app-footer-project-name{
    font-size: 20px;
    font-weight: 500;
}

.app-footer-project-name img{
    height: 40px;
    width: 150px;
}

.app-footer-policy{
  display: flex;
  gap:10px;
  margin-top: 10px;

}


.contant-app-image{
    width: 500px;
    justify-content: space-between;
}

.contant-app-image img{
    height: 550px;
}

.ant-form-item {
    margin-bottom: 4px !important;
}

.ant-input {
    border-radius: 4px;
}

.ant-form-item .ant-form-item-label>label {
    font-size: 12px;
    font-weight: 500;
}

.ant-card .ant-card-head {
    background: #7820b6;
    color: #ffffff;
}

.ant-card .ant-card-body {
    padding: 8px
}

.ant-form-item .ant-form-item-explain-error {
    font-size: 10px;
    text-align: right;
    font-style: italic;
}

.p-0 {
    padding: 0;
}

.p-1 {
    padding: 1rem;
}

.p-2 {
    padding: 2rem;
}

.p-3 {
    padding: 3rem;
}

.p-10 {
    padding: 10px
}

.p-20 {
    padding: 20px
}

.p-30 {
    padding: 30px;
}

.pl-10 {
    padding-left: 10px;
}

.pb-10 {
    padding-bottom: 10px;
}

.m-0 {
    margin: 0;
}

.m-1 {
    margin: 1rem;
}

.m-2 {
    margin: 2rem;
}

.m-3 {
    margin: 3rem;
}

.w-100 {
    width: 100%;
}

.mt-0-5 {
    margin-top: 0.5rem
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.text-align-center {
    text-align: center;
}

.font-weight-bold {
    font-weight: bold;
}


@media only screen and (max-width: 1200px) {
  .app-header-logo img{
    height: 100px;
    width: 250px;
  }
  .app-header-contact-button{
    font-size: 14px;
  }
  .app-content{
    display: block;
    padding:15px;
  }

  .content-mobile-app-text-1{
    font-size:40px;
  }

  .content-mobile-app-text-2{
    font-size:44px;
  }

  .content-mobile-app-text-3{
    font-size: 30px;
  }

  .content-mobile-app-text-4{
    font-size: 25px;
  }
  .mobile-app-store-content{
    justify-content: center;
    margin-bottom: 50px;
    margin-top:50px
  }

  .mobile-app-store-content-detail-item{
    padding: 10px;
  }

  .contant-app-image{
    width: 100%;
    text-align: center;
  }

  .contant-app-image img{
    height: 250px;

    justify-content: center;
    text-align: center;
  }

  .app-footer{
    margin-top:20px;
    text-align: center;
  }

  .app-footer-policy{
    justify-content: center;
    font-size: 12px;
  }
}